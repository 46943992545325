<template>
  <base-page>
    <el-card style="margin: 5px 0px 5px 0px">
      <el-collapse accordion style="border: none">
        <el-collapse-item name="1" style="border: none">
          <template slot="title">
            <div slot="header" class="clearfix">
              <titlu-pagina
                Titlu="Luni blocate"
                @on_add_clicked="show_dialog()"
                :AdaugaVisible="true"
              />
            </div>
          </template>
          <div class="filtre">
            <el-form
              @submit.prevent.native="refresh_info()"
              label-position="top"
            >
              <el-row :gutter="20">
                <el-col>
                  <el-form-item label="An">
                    <el-date-picker
                      class="full-width"
                      v-model="Filters.An"
                      type="year"
                      placeholder="Alege anul"
                      value-format="yyyy"
                      format="yyyy"
                    />
                  </el-form-item>
                </el-col>
              </el-row>
              <el-col style="width: 100%">
                <div class="footer-form">
                  <el-button
                    type="primary"
                    native-type="submit"
                    @click="refresh_info()"
                  >
                    Aplica
                  </el-button>
                  <el-button
                    type="danger"
                    native-type="submit"
                    @click="reset()"
                  >
                    Reseteaza
                  </el-button>
                </div>
              </el-col>
            </el-form>
          </div>
        </el-collapse-item>
      </el-collapse>
    </el-card>

    <el-table :header-cell-style="{ background: '#393E46' }" el-table :data="Results">
      <el-table-column prop="An" label="An"></el-table-column>
      <el-table-column label="Luna">
        <template slot-scope="scope">
          {{ scope.row.Luna | LunaLitere }}
        </template>
      </el-table-column>
      <el-table-column label="Deschisa">
        <template slot-scope="scope">
          <el-switch
            v-model="scope.row.Deschisa"
            active-value="1"
            inactive-value="0"
            active-text="Deschisa"
            inactive-text="Inchisa"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="modifica_luna(scope.row)"
          />
        </template>
      </el-table-column>
      <el-table-column style="text-align: right;" label="Operatiuni">
        <template slot-scope="scope">
          <el-tooltip v-if="!scope.row.Alocata" content="Exporta contabilitate">
            <el-button
              type="primary"
              icon="el-icon-download"
              size="mini"
              circle
              @click="exporta_winmentor(scope.row)"
              class="butonReg" />
          </el-tooltip>
          <!-- {{ scope.row.Luna | LunaLitere }} -->
        </template>
      </el-table-column>
    </el-table>

    <el-pagination
      @size-change="refresh_info"
      @current-change="refresh_info"
      :page-size.sync="PaginationInfo.PerPage"
      :current-page.sync="PaginationInfo.Page"
      :total="PaginationInfo.RowCount"
      layout="pager"
    />
    <Luni-blocate-dialog ref="dlg" @save="refresh_info()" />
  </base-page>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";
import Luni_blocate_dialog from "@/pages/luni_blocate/Luni_blocate_dialog.vue";
import TitluPagina from "@/widgets/TitluPagina";

export default {
  name: "luni_blocate",
  extends: BasePage,
  components: {
    "base-page": BasePage,
    "Luni-blocate-dialog": Luni_blocate_dialog,
    "titlu-pagina": TitluPagina,
  },
  data() {
    return {
      Results: [],
      base_url: "",
      Info: {
        // clienti: [],
      },
      Filters: {
        An: "",
      },
      OrderBy: {},
      PaginationInfo: {
        Page: 1,
        PerPage: 50,
        RowCount: 0,
        PageSizes: [10, 25, 50, 100, 200],
      },
    };
  },
  methods: {
    async get_info() {
      // var response = await this.post("luni_blocate/get_info_for_dialog");
      // this.Info.luni_existente = response.luni_existente;
      // if (this.Filters.IdClient.length > 0)
      //   this.Filters.IdClient = this.Info.clienti[0].Id;
      this.refresh_info();
    },





    async refresh_info() {
      var response = await this.post("luni_blocate/index", {
        Filters: this.Filters,
        OrderBy: this.OrderBy,
        PaginationInfo: this.PaginationInfo,
      });
      this.Results = response.Results;
      this.PaginationInfo = response.PaginationInfo;
      //
      this.select_menu_item("dictionar");
    },
    reset() {
      this.Filters = {
        An: "",
      };
      this.refresh_info();
    },

    async modifica_luna(linie) {
      if (linie.Deschisa == 1) {
        this.$confirm(
          `Sunteti sigur ?`,
          "Se va debloca luna! Se vor putea introduce si modifica documente",
          {
            type: "warning",
          }
        )
          .then(async () => {
            await this.post("luni_blocate/modifica_luna", { linie: linie });
            this.refresh_info();
          })
          .catch(() => {
            // var luna = this.Results.filter((luna) => luna.Id == linie.Id)[0];
            linie.Deschisa = 0;
          });
      } else {
        await this.post("luni_blocate/modifica_luna", { linie: linie });
        this.refresh_info();
      }
    },

    async exporta_winmentor(linie) {
   
      let res = await this.post("luni_blocate/exporta_winmentor", { luna: linie });



      let blob = new Blob([res], { type: 'application/xml' })
      let link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'FacturiClienti.txt'
      link.click()


      let qulq = new Blob([res], { type: 'application/xml' })
      let linci = document.createElement('a')
      linci.href = window.URL.createObjectURL(qulq)
      linci.download = 'FacturiFurnizori.txt'
      linci.click()
           
    },

    show_dialog(id) {
      this.$refs.dlg.show_me(id);
    },
  },
  mounted() {
    // if (!this.$has_right('Management') && !this.$has_right('Supervizor')){
    //   this.$router.push("/no-rights");
    //   return;
    // }
    this.base_url = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.top50 {
  margin-top: 20px;
}

@media only screen and (min-width: 768px) {
  .filtre {
    display: flex;
    grid-template-columns: none;
  }
  .el-table tr {
    word-break: break-word;
    // font-size: 12px !important;
  }
  .footer-form {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.filtre {
  width: 100% !important;
  display: grid;
  grid-template-columns: repeat(2);
  .el-col {
    width: 50%;
  }

  // grid-auto-rows: 100px;
  .el-input-number {
    width: 100% !important;
  }
}
</style>
