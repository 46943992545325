<template>
  <el-dialog
    title="Luna blocata"
    :visible.sync="showPopup"
    id="popup_dialog"
    width="90%"
    class="my-dialog-class"
    append-to-body
  >
    <el-form
      label-position="top"
      :inline="false"
      :rules="rules"
      label-width="100%"
      :model="selectedObject"
      ref="my-form"
      @submit.prevent="save"
      v-loading="loadingVisible"
    >
      <el-row :gutter="15">
        <el-col :md="8">
          <el-form-item prop="LunaAn" label="Nume">
            <el-date-picker
              :picker-options="datePickerOptions"
              class="full-width"
              v-model="selectedObject.LunaAn"
              type="month"
              placeholder="Alege luna si an"
              value-format="yyyy-MM"
              format="MM.yyyy"
            />
          </el-form-item>
        </el-col>

        <!-- <el-col>
          <el-form-item>
            <el-switch
              v-model="selectedObject.Deschisa"
              true-label="1"
              false-label="0"
              active-text="Deschisa"
              active-color="#13ce66"
              inactive-color="#ff4949"
            >
            </el-switch> -->
            <!-- <el-checkbox class="full-width" v-model="selectedObject.Dezactivat" true-label="1" false-label="0">
              Dezactivat
            </el-checkbox> -->
          <!-- </el-form-item>
        </el-col> -->
      </el-row>
    </el-form>
    <span slot="footer" class="dialog-footer">
      <el-button @click="showPopup = false"> Renunta </el-button>
      <el-button type="primary" @click="save"> Salveaza </el-button>
    </span>
  </el-dialog>
</template>

<script>
import settings from "@/backend/LocalSettings";
import BasePage from "@/pages/BasePage";

export default {
  name: "Luni_blocate_dialog",
  extends: BasePage,
  components: {},
  data() {
    return {
      datePickerOptions: {
        disabledDate(date) {
          return date > new Date();
        },
      },
      baseUrl: "",
      showPopup: false,
      mode: "add",
      selectedObject: {
        LunaAn: "",
        Deschisa: "",
      },
      Info: {
        // clienti: [],
      },
      rules: {
        LunaAn: [{ required: true, message: "Campul este obligatoriu" }],
      },
    };
  },
  methods: {
    show_me: async function (id) {
      await this.get_info();
      this.selectedObject = {
        LunaAn: "",
        Deschisa: "",
      };
      this.showPopup = true;
      if (id == null) {
        this.mode = "add";
      } else {
        this.mode = "edit";
        var result = await this.post("luni_blocate/get_info_item_dialog", {
          id: id,
        });
        this.selectedObject = result.Item;
      }
    },

    async get_info() {
      var response = await this.post("luni_blocate/get_info_for_dialog");
      this.luni_existente = response.luni_existente;
    },
    save: async function () {
      if (this.luni_existente != null) {
        if (this.luni_existente.includes(this.selectedObject.LunaAn)) {
          this.$notify({
            title: "Atentie",
            message: "Luna selectata exista deja in lista",
            dangerouslyUseHTMLString: true,
            type: "warning",
            duration: 5000,
          });
          return;
        }
      }

      this.$refs["my-form"].validate(async (valid) => {
        if (valid) {
          let result = await this.post("luni_blocate/adauga", {
            object: this.selectedObject,
          });
          this.showPopup = false;
          this.$emit("save", result.obj);
        }
      });
    },
  },
  mounted: function () {
    this.baseUrl = settings.BASE_URL;
    this.get_info();
  },
};
</script>

<style lang="less" scoped>
.full-width {
  width: 100%;
}
</style>
